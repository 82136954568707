body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Vulf Mono */

@font-face {
  font-family: 'Vulf-mono';
  src: url('./assets/fonts/Vulf_Mono-Regular_web.ttf') format('truetype'),
        url('./assets/fonts/Vulf_Mono-Regular_web.woff') format('woff'),
        url('./assets/fonts/Vulf_Mono-Regular_web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vulf-mono';
  src: url('./assets/fonts/Vulf_Mono-Bold_web.ttf') format('truetype'),
        url('./assets/fonts/Vulf_Mono-Bold_web.woff') format('woff'),
        url('./assets/fonts/Vulf_Mono-Bold_web.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/* Vulf Sans */

@font-face {
  font-family: 'Vulf-sans';
  src: url('./assets/fonts/Vulf_Sans-Regular.ttf') format('truetype'),
        url('./assets/fonts/Vulf_Sans-Regular.woff') format('woff'),
        url('./assets/fonts/Vulf_Sans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vulf-sans';
  src: url('./assets/fonts/Vulf_Sans-Bold.ttf') format('truetype'),
        url('./assets/fonts/Vulf_Sans-Bold.woff') format('woff'),
        url('./assets/fonts/Vulf_Sans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}