html, #root, .App {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
}

.ant-layout {
  font-family: 'Vulf-mono', sans-serif;
  position: relative;
  min-height: 100% !important;
  display: flex;
  justify-content: space-between;
}

.menu {
  display: flex;
  align-items: flex-end;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
}

.nav-link {
  align-items: flex-end;
}

.site-layout-content {
  margin: 6rem 4rem 4rem;
  padding-bottom: 6rem;
}

.footer {
  position: absolute;
  margin: auto;
  padding: 2rem 2rem 1rem !important;
  bottom: 0;
  width: 100%;
}

.footer-link {
  margin-right: 2vh;
  color: black !important;
}

.footer-copyright {
  padding-top: 16px;
}

@media only screen and (max-width: 600px) {
  .site-layout-content {
    margin: 5rem 2rem 2rem;
  }
  .menu {
    align-items: flex-start;
  }
  #logo {
    font-size: 1.5rem;
    padding-right: 10vh;
  }
  .ant-layout-header {
    padding: 0;
  }
  #header {
    padding: 0px;
  }
  #about-me {
    margin-left: 0;
    margin-right: 0;
  }
  .ant-menu-submenu {
    margin-top: -rem;
    padding-left: 14.5rem;
  }
}