h1 {
  font-weight: bold;
}

p {
  font-family: 'Vulf-sans', sans-serif;
}

.projects-container {
  display: flex;
  align-items: center;
}

.project-card {
  margin-top: 8vh;
  max-width: 68vh;
}

.ant-card-cover {
  width: 100%;
  padding: 0 1rem;
}

.project-card img {
  padding: 8px;
}

.ant-card-body {
  display: flex;
  flex-direction: column;
}

.project-container a {
  font-weight: bold;
  padding: 4px;
}

.ant-card-head-title {
  font-size: 2rem;
  font-weight: bold;
}